// Import libs
import React from "react";

// Import shared components
import {
  Indicator,
  IndicatorGroup
} from "@windish/shared";

// Component
function Trackrecord() {

  // Write the final component code to DOM
  return (
    <IndicatorGroup>
      <Indicator
        value="15"
        description="years of experience in design, journalism and software."
      />
      <Indicator
        value="10+"
        description="companies I worked with to launch new data products."
      />
      <Indicator
        value="3"
        description="internationally succesfull teams I helped establish."
      />
      <Indicator
        value="100s"
        description="of amazing people in my international network."
      />
      <Indicator
        value="50+"
        description="lectures, workshops and training programs to date."
      />
      <Indicator
        value="100s"
        description="of international attendants, students and professionals."
      />
      <Indicator
        value="20+"
        description="talents I discovered and helped progress in their career."
      />
      <Indicator
        value="4.3/5"
        description="average satisfaction score, based on their feedback."
      />
    </IndicatorGroup>
  );

}

// Export the component for use
export default Trackrecord;