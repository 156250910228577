// Import libs
import React from "react";
import { useStaticQuery, graphql } from "gatsby"

// Import the common component to be wrapped
import {
  PostCard,
  PostCardGroup
} from "@windish/shared";

// Wrap the common component for local use
function Posts() {

  // Add local data
  const postsData = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          published: { eq: true },
        },
        sort: {
          order: DESC,
          fields: [frontmatter___year]
        },
        limit: 4
      ){
        edges {
          node {
            id
            slug
            frontmatter {
              cover_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              cover_image_alt
              heading
              client
              year
              draft
              locked
            }
          }
        }
      }
    }
  `)
  return (
    <PostCardGroup>
      {postsData.allMdx.edges.map(edge => (
        <PostCard
          key={edge.node.id}
          coverImage={edge.node.frontmatter.cover_image.childImageSharp.gatsbyImageData}
          coverImageAlt={edge.node.frontmatter.cover_image_alt}
          client={edge.node.frontmatter.client}
          heading={edge.node.frontmatter.heading}
          slug={edge.node.slug}
          draft={edge.node.frontmatter.draft}
          locked={edge.node.frontmatter.locked}
        />
      ))}
    </PostCardGroup>
  )

}

// Export the component for use
export default Posts;
