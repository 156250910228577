// Import libs
import React from "react";

// Import shared components
import {
  Button,
  Cover,
  Paragraph,
  Container,
  PostContainer,
  IndicatorContainer,
  ReferenceContainer
} from "@windish/shared";

// Import local components
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Posts from "../components/Posts";
import Trackrecord from "../components/Trackrecord";
import Contact from "../components/Contact";
import Impact from "../components/Impact";

// Import page-specific CSS
import "./index.css"

// Page constants
const pageTitle = "Designer & mentor";
const pageDescription = "Building humanistic data products & world-class teams behind them";

// Page
export default function Index() {

  // Render page
  return (
    <Layout pageTitle={pageTitle}>
      <Cover
        heading={pageDescription}
      />
      <ReferenceContainer>
        <Impact />
        <Button type="cta" label="See my full impact" icon="arrow_forward" animate="right" link={true} linkHref="/impact" />
      </ReferenceContainer>
      <PostContainer>
        <Paragraph>My work is focused on human-data interaction: spaning IoT and digital twins, infographics and data visualization, cartography and spatial orientation, atomic content and contextual information.</Paragraph>
      </PostContainer>
      <Container>
        <Posts />
        <Button type="cta" label="Explore all of my works" icon="arrow_forward" animate="right" link={true} linkHref="/works" />
      </Container>
      <PostContainer>
        <Paragraph>We have reached the limit of data comprehension and, in effect, data utility. Working at the intersection of design and technology, I have dedicated my career to better capture and use of data; helping others do the same.</Paragraph>
      </PostContainer>
      <IndicatorContainer>
        <Trackrecord />
        <Button type="cta" label="Learn more about me" icon="arrow_forward" animate="right" link={true} linkHref="/about" />
      </IndicatorContainer>
      <PostContainer>
        <Paragraph>Growing complexity of software and rapid proliferation of connected devices makes the inherent issues bigger, by the day. We need to humanize data, now! Let's do it, together &ndash; through new ventures or community work.</Paragraph>
      </PostContainer>
      <Contact />
    </Layout>
  );
}

// Page metadata
export const Head = () => (
  <Seo
    title={pageTitle}
    description={pageDescription}
  >
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://vindis.io/",
          "sameAs": [
            "https://twitter.com/AljazVindis",
            "https://www.linkedin.com/in/windish/",
            "https://github.com/windish"
          ],
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
    </script>
  </Seo>
)
